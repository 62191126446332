export const BorsAppIcon = ({ size = '40px' }) => (
  <svg width={size} height={size} viewBox={`0 0 134 134`}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
      <g id="Logo" transform="translate(-63.000000, -65.000000)" stroke="#FFFFFF">
        <g id="percent" transform="translate(71.000000, 73.000000)">
          <line x1="4" y1="112" x2="112" y2="4" id="Stroke-1" strokeWidth="12" strokeLinecap="round"></line>
          <polygon id="Stroke-2" strokeWidth="12" points="77 94.5 97.4965839 74 118 94.5 97.4965839 115"></polygon>
          <polygon id="Stroke-3" strokeWidth="12" points="0 20.5 20.5 0 41 20.5 20.5 41"></polygon>
        </g>
      </g>
    </g>
  </svg>
)