import { useState } from 'react';
import FundDetailModal from './FundDetailModal';

function Fund({ fund }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);
  const {
    name,
    changeSinceOneMonth,
    changeSinceThreeMonths,
    changeSinceOneYear,
    score,
    type,
  } = fund;

  const getColor = (number) => {
    const isPos = number > 0;
    return isPos ? 'text-cyan-500' : 'text-pink-500';
  };
  return (
    <>
      <div
        onClick={() => toggleModal()}
        className="focus false w-full cursor-pointer p-6 outline-none hover:bg-white hover:bg-opacity-[0.02] focus-visible:bg-white focus-visible:bg-opacity-[0.02] md:p-[60px]"
      >
        <div className="flex flex-wrap     items-center lg:flex-nowrap">
          <div className="flex w-full items-center">
            <div className="flex w-full flex-col items-start space-y-1 font-bold lg:pl-8">
              <div> {name} </div>
              <div className="text-sm text-gray-400"> {type} </div>{' '}
            </div>{' '}
          </div>{' '}
          <div className="mt-6 flex w-full justify-between lg:mt-0 lg:space-x-16">
            <div className="min-w-[85px] space-y-1 text-left">
              <div className="t-12 whitespace-nowrap text-sm uppercase text-gray-400">
                1 mån{' '}
              </div>{' '}
              <div
                className={`flex items-center space-x-2 ${getColor(
                  changeSinceOneMonth
                )}`}
              >
                {changeSinceOneMonth ? changeSinceOneMonth + '%' : 'N/A'}{' '}
              </div>{' '}
            </div>
            <div className="min-w-[85px] space-y-1 text-left">
              <div className="t-12 whitespace-nowrap text-sm uppercase text-gray-400">
                3 mån{' '}
              </div>{' '}
              <div
                className={`flex items-center space-x-2 ${getColor(
                  changeSinceThreeMonths
                )}`}
              >
                {changeSinceThreeMonths ? changeSinceThreeMonths + '%' : 'N/A'}{' '}
              </div>{' '}
            </div>
            <div className="min-w-[85px] space-y-1 text-left">
              <div className="t-12 whitespace-nowrap text-sm uppercase text-gray-400">
                1 år{' '}
              </div>{' '}
              <div
                className={`flex items-center space-x-2 ${getColor(
                  changeSinceOneYear
                )}`}
              >
                {changeSinceOneYear ? changeSinceOneYear + '%' : 'N/A'}{' '}
              </div>{' '}
            </div>
            <div className="min-w-[85px] space-y-1 text-left">
              <div className="t-12 whitespace-nowrap text-sm uppercase text-gray-400">
                Poäng{' '}
              </div>{' '}
              <div className="flex items-center space-x-2"> {score} </div>{' '}
            </div>{' '}
          </div>{' '}
        </div>{' '}
      </div>{' '}
      <FundDetailModal isOpen={isOpen} toggle={toggleModal} fund={fund} />{' '}
    </>
  );
}

export default Fund;
