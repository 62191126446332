import { useState } from 'react';
import FundDetailModal from './FundDetailModal';

function SelectedFund({ fund }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const {
    name,
    type,
  } = fund;

  return (
    <>
      <div
        onClick={toggleModal}
        className="focus false w-full cursor-pointer overflow-hidden p-6 outline-none hover:bg-[#0F0F10] focus-visible:bg-white focus-visible:bg-opacity-[0.02] lg:py-8"
      >
        <div className="flex flex-wrap items-center lg:flex-nowrap">
          <div className="flex w-full items-center">
            <div className="flex w-full flex-col items-start space-y-1 font-bold lg:px-6">
              <div>{name}</div>
              <div className="text-sm text-gray-400">{type}</div>
            </div>
          </div>
        </div>
      </div>
      <FundDetailModal isOpen={isOpen} toggle={toggleModal} fund={fund} />
    </>
  );
}

export default SelectedFund;
