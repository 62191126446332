import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';

export default function Info() {
  return (
    <div>
      <p>Denna sida visar ett praktiskt exempel på hur man kan följa en kvantitativ investeringsstrategi som heter GTAA AGG3. Tanken är att det ska bli enklare både för mig och andra som är intresserade att förstå och följa strategin genom denna sida. Portföljens utveckling redovisas för transparensens skull och för att göra det lättare att utvärdera ifall strategin är något för dig.</p>

      <div className="my-6 mt-10">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full p-4 font-medium text-left text-white bg-[#161618] rounded-lg hover:bg-[#212124] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <span>Hur funkar strategin</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-300">
                <p className="mb-6">Strategin är i grunden ganska enkel. Det är en så kallad kvantitativ investeringsstrategi går ut på att du investerar baserat på historisk data, mönster eller andra specifika parametrar utan att blanda in känslor eller egen analys. Den här strategin bygger på Meb Fabers studier om kvantitativa investeringar i olika tillgångsslag. Ni kan fördjupa er i ämnet <a className="underline underline-offset-2 hover:text-white transition-all transition-duration-250" href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=962461">här</a>.</p>

                <p>Strategin heter GTAA AGG3 vilket står för Global Tactical Asset Allocation och AGG3 säger att den är Aggressive och håller totalt 3 innehav. Tanken med strategin är att man ska samla ihop ett rimligt antal tillgångar (runt 13st) som är så diversifierade från varandra som möjligt. Bland dessa 13 tillgångar kan man även spetsa med vissa speciella nischer som tex teknik eller fastigheter. När man har valt sinna tillgångar man får investera i så är reglerna dessa:</p>

                <ol className="my-4 list-decimal ml-8">
                  <li className="py-1">Efter börsens stängning skall du, en gång i månaden, ta fram avkastningen för varje tillgång på 1, 3, 6 och 12 månaders sikt.</li>
                  <li className="py-1">Räkna ut medelvärdet av dessa fyra värden ovanför.</li>
                  <li className="py-1">Köp de 3 tillgångar som har högst medelvärde. Fördela lika mycket pengar till varje innehav.</li>
                  <li className="py-1">Balansera om portföljen varje månad oavsett om det har skett en förändring i innehav eller ej.</li>
                </ol>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>

      <div className="my-6">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full p-4 font-medium text-left text-white bg-[#161618] rounded-lg hover:bg-[#212124] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <span>Några kommentarer</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-300">
                <ul className="my-4 list-disc ml-8">
                  <li className="py-1">Denna strategi använder sig egentligen av ETF:er, men eftersom utbudet av dessa är så dåligt i Sverige så används fonder här istället.</li>
                  <li className="py-1">Just nu redovisas inte avkastningen på 6 månaders sikt för fonderna. Men värdet tas med i uträkningen av poängen för varje tillgång. Snart kommer värdet också synas på sidan.</li>
                  <li className="py-1">Jag har valt att kalla medelvärdet för poäng för att göra listan lite mer av en high score än att ha ytterligare en procentsats. Men det är egentligen medelvärdet i procent av avkastningen för varje tidshorisont.</li>
                  <li className="py-1">Personligen balanserar jag inte om så att mina tillgångar har exakt samma värde varje månad. Är de ungefär likvärdiga så låter jag dem vara och balanserar sedan vid nyinsättningar.</li>
                  <li className="py-1">Vissa varianter utgår endast från 3 månaders avkastning och rankar innehaven efter det. Jag körde så förut men valde att gå över på sammansatt momentum efter att <a className="underline underline-offset-2 hover:text-white transition-all transition-duration-250" href="https://www.marketmakers.se/">Market Makers</a> liknande portfölj, som använde sammansatt momentum, gick bättre än min.</li>
                  <li className="py-1">Ibland kan man se att det finns en till regel som säger att man inte ska investera ifall tillgången stänger under sitt 10-månaders glidande medelvärde eller ifall det sammansatta momentumet är negativt. Men det har också gjorts en del tester på att denna regel spelar mindre roll vilket gör att jag inte tar med den.</li>
                </ul>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>

      <div className="my-6">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full p-4 font-medium text-left text-white bg-[#161618] rounded-lg hover:bg-[#212124] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <span>Bakgrund och historia</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-300">
              <p className="mb-6">
                Jag började med denna strategi runt 2017/2018 och då genom att importera data till Google Sheets för att göra uträkningar och ranking. Men det var oftare än sällan som dessa importer slutade fungera så datan blev föråldrad. Minsta lilla misstag gjorde också att dokumentet mer eller mindre blev korrupt så situationen var ohållbar.
              </p>
              <p className="mb-6">
                Så istället byggde jag upp en bevakningslista på Avanza där jag lättare kunde följa och ranka om allokeringen. Men då baserade jag det nya valet endast på de tre senaste månadernas utveckling och inte sammansatt utveckling (mer om det här nedan). Jag bytte till sammansatt momentum efter att Market Makers portfölj (som använder sammansatt momentum) gick bättre än min.
              </p>
              <p className="mb-6">
                Även om strategin endast kräver att du kollar över den en gång i månaden för att göra dina omplaceringar så missade jag det flera gånger. Det kunde bli förskjutet mer några dagar eller missas helt. Jag har också mer och mer skiftat min tid från att sitta med näsan i börsen till att utveckla produkter/tjänster så det kändes inte mer än rätt att automatisera detta.
              </p>
              <p className="mb-6">
                🤖 BörsApp är idag helt automatiserad och uppdaterar rankingen dagligen, gör nytt urval och lägger ordrar automatiskt på kvällen till den 25:e varje månad.
              </p>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}