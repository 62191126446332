import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

export default function MyModal({ isOpen, toggle, fund }) {
  const riskTranslate = {
    LOW: 'Låg',
    MIDDLE: 'Mellan',
    HIGH: 'Hög',
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={toggle}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-blur-[6px]" />
          </Transition.Child>
          {/* bg-[#0F0F10] opacity-50 */}

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-block w-full max-w-3xl transform overflow-hidden rounded-2xl bg-black p-2 text-left align-middle shadow-xl transition-all sm:p-6 lg:p-12">
              <div className="flex items-center">
                <Dialog.Title
                  as="h3"
                  className="flex-auto text-lg font-medium leading-6 text-white lg:text-2xl"
                >
                  {fund.name}
                </Dialog.Title>
                <button
                  type="button"
                  onClick={toggle}
                  className="ml-2 rounded-md bg-[#0F0F10] p-3 transition-colors ease-linear hover:bg-[#1a1a1b] focus:outline-none"
                >
                  <svg
                    className="h-6 w-6"
                    fill="white"
                    viewBox="0 0 24 24"
                    stroke="white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div className="mt-8 grid grid-cols-3 divide-x divide-white divide-opacity-5 rounded-lg bg-[#0F0F10] px-1 py-8 text-sm text-white md:text-base lg:px-3">
                <div className="min-w-[100px] space-y-1 px-4 text-center lg:px-8">
                  <div className="t-12 whitespace-nowrap text-sm uppercase text-gray-400">
                    Risknivå
                  </div>
                  <div className="font-bold">
                    {riskTranslate[fund.riskLevel]} ({fund.risk})
                  </div>
                </div>
                <div className="min-w-[100px] space-y-1 px-4 text-center lg:px-8">
                  <div className="t-12 whitespace-nowrap text-sm uppercase text-gray-400">
                    Avgift
                  </div>
                  <div className="font-bold">{fund.managementFee}%</div>
                </div>
                <div className="min-w-[100px] space-y-1 px-4 text-center lg:px-8">
                  <div className="t-12 whitespace-nowrap text-sm uppercase text-gray-400">
                    Rating
                  </div>
                  <div className="font-bold">{fund.rating || 'N/A'}</div>
                </div>
              </div>
              <div className="mt-8">
                <p className="text-sm text-gray-300 md:text-base">
                  {fund.description}
                </p>
              </div>

              <div className="my-4 text-center">
                <button
                  type="button"
                  className="inline-flex justify-center px-2 md:px-4 py-2 text-xs md:text-sm font-medium text-white bg-black border border-gray-400 rounded-md hover:bg-white hover:text-black focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                  onClick={() => window.location.href = `https://www.avanza.se/fonder/om-fonden.html/${fund.fundId}`}
                >
                  Läs mer på Avanza
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
