import { useMemo } from 'react';
import moment from 'moment';

function AllocateTimer() {
  const allocationDates = useMemo(() => {
    const today = new Date();
    const allocateNextMonth = today.getDate() > 24;
    const nextAllocation = moment().date(24).hour(23).minute(59);
    const prevAllocation = moment().date(24).hour(23).minute(59);
    let formattedPercentage = 0;

    if (allocateNextMonth) {
      nextAllocation.add(1, 'M');
    } else {
      prevAllocation.subtract(1, 'M');
    }
    const now = moment();
    var percentage =
      (now.diff(prevAllocation) / nextAllocation.diff(prevAllocation)) * 100;

    if (percentage > 100) {
      formattedPercentage = 100;
    } else {
      formattedPercentage = percentage.toFixed(2);
    }

    return {
      nextAllocation,
      prevAllocation,
      progress: formattedPercentage,
    };
  }, []);

  return (
    <div className="rounded-xl border border-solid border-zinc-800 p-6 md:p-8 lg:px-20 lg:py-12">
      <div className="mb-1 flex items-center justify-between">
        <span className="font-carbon text-lg font-medium uppercase dark:text-white">
          Nästa allokering
        </span>
        <span className="text-base font-medium dark:text-white">
          {allocationDates.nextAllocation.format('YYYY-MM-DD')}
        </span>
      </div>
      <div className="my-4 h-3.5 w-full rounded-full bg-zinc-800">
        <div
          className="h-3.5 rounded-full bg-white"
          style={{ width: `${allocationDates.progress}%` }}
        ></div>
      </div>
      <div className="mb-1 flex justify-between">
        <span className="text-sm font-medium text-zinc-400">
          {moment().diff(allocationDates.prevAllocation, 'days') + 1} dagar
          sedan
        </span>
        <span className="text-sm font-medium text-zinc-400">
          {allocationDates.nextAllocation.diff(moment(), 'days')} dagar kvar
        </span>
      </div>
    </div>
  );
}

export default AllocateTimer;
