import { useEffect, useMemo, useState } from 'react';
import ChartistGraph from 'react-chartist';
import Chartist from 'chartist';
import 'chartist-plugin-legend';
import moment from 'moment';

function AccountChart() {
  const [chartData, setChartData] = useState();
  const [timePeriod, setTimePeriod] = useState('ONE_YEAR');

  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
  };

  useEffect(() => {
    fetch(`/api/chartdata?timeperiod=${timePeriod}`)
      .then((res) => res.json())
      .then((data) => setChartData(data))
      .catch((e) => console.log(e));
  }, [timePeriod]);

  const returnSeries = useMemo(
    () =>
      chartData &&
      chartData.dataSeries.map((entry) => ({
        x: new Date(entry.date),
        y: entry.value,
      })),
    [chartData]
  );

  const comparisonSeries = useMemo(
    () =>
      chartData &&
      chartData.comparisonSeries.map((entry) => ({
        x: new Date(entry.date),
        y: entry.value,
      })),
    [chartData]
  );

  if (!chartData) {
    return null;
  }

  const apiData = {
    series: [
      {
        name: 'OMXS 30',
        data: comparisonSeries,
      },
      {
        name: 'BÖRSAPP',
        data: returnSeries,
      },
    ],
  };
  const dateOptions = {
    stretch: true,
    high: chartData.ceiling,
    low: chartData.floor,
    fullWidth: false,
    showPoint: false,
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    showLine: true,
    axisX: {
      type: Chartist.FixedScaleAxis,
      divisor: 8,
      labelInterpolationFnc: function (value) {
        return moment(value).format('D MMM');
      },
    },
    axisY: {
      offset: 40,
      showGrid: true,
      position: 'end',
      labelInterpolationFnc: function (value, index) {
        return index % 2 === 0 ? `${value}%` : null;
      },
    },
    plugins: [
      // Chartist.plugins.legend()
    ],
  };
  const timePeriods = [
    {
      value: 'ONE_MONTH',
      label: '1M',
    },
    {
      value: 'THREE_MONTHS',
      label: '3M',
    },
    {
      value: 'THIS_YEAR',
      label: 'YTD',
    },
    {
      value: 'ONE_YEAR',
      label: '1Y',
    },
    {
      value: 'THREE_YEARS',
      label: '3Y',
    },
  ];

  const responsiveOptions = [
    [
      'screen and (max-width: 640px)',
      {
        axisX: {
          type: Chartist.FixedScaleAxis,
          divisor: 4,
          labelInterpolationFnc: function (value) {
            return moment(value).format('D MMM');
          },
        },
      },
    ],
  ];

  return (
    <div className="flex grow flex-col">
      <div className="flex items-baseline justify-between space-x-1 text-xs">
        {/* <div className="text-xl font-carbon">{chartData.dataSeries?.pop().value.toFixed(2)}%</div> */}
        <div className="font-carbon text-xl"> </div>
        <ul className="inline-flex space-x-1" onChange={handleTimePeriodChange}>
          {timePeriods.map((period) => (
            <li key={period.value}>
              <input
                type="radio"
                id={period.value}
                name="time-period"
                value={period.value}
                defaultChecked={period.value === timePeriod}
                className="peer hidden"
                required
              />
              <label
                htmlFor={period.value}
                className="inline-flex w-full cursor-pointer items-center justify-between rounded-lg border border-zinc-600 p-2 text-white hover:bg-zinc-600 peer-checked:bg-gray-100 peer-checked:text-gray-800"
              >
                <div className="block">
                  <div className="w-full text-xs font-semibold">
                    {period.label}
                  </div>
                </div>
              </label>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-2 flex grow">
        <ChartistGraph
          data={apiData}
          options={dateOptions}
          responsiveOptions={responsiveOptions}
          type="Line"
        />
      </div>
    </div>
  );
}

export default AccountChart;
