import { useEffect, useState } from 'react';
import Fund from './Fund';
import SelectedFund from './SelectedFund';
import AccountChart from './components/AccountChart';
import AllocateTimer from './components/AllocateTimer';
import SlideOverPanel from './ReadMorePanel/SlideOverPanel';
import { BorsAppIcon } from './icons';

function App() {
  const [list, setList] = useState([]);
  const [showReadMore, setShowReadMore] = useState(false);
  const [allocated, setAllocated] = useState([]);
  const [decoratedAllocated, setDecoratedAllocated] = useState([]);

  useEffect(() => {
    fetch('/api/ranking')
      .then((res) => res.json())
      .then((data) => setList(data))
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    fetch('/api/allocated')
      .then((res) => res.json())
      .then((data) => setAllocated(data))
      .catch((e) => console.log(e));
  }, []);

  // Decorate allocated list
  useEffect(() => {
    if (list.length === 0 || allocated.length === 0) {
      return;
    }

    const decoratedList = allocated.map((fund) => {
      return list.find((rankedFund) => rankedFund.fundId === fund.fundId.toString());
    });

    setDecoratedAllocated(decoratedList);
  }, [list, allocated]);

  // En praktisk tillämpning av en global trendföljande fondstrategi
  return (
    <div className="min-h-screen bg-black pb-12 text-white">
      <header className="container mx-auto mb-0 p-4 lg:py-12">
        <SlideOverPanel isOpen={showReadMore} toggle={setShowReadMore} />
        <div className="top-bar flex items-center justify-between">
          <BorsAppIcon size="30" />
          <button onClick={() => setShowReadMore(true)} className="flex text-xs md:text-sm font-medium items-center rounded-full px-6 py-4 leading-[1] border-[2px] hover:border-zinc-600 transition-all transition-duration-[1s] border-zinc-800">
            <span className="font-carbon">LÄS MER</span>
          </button>
        </div>

        <div className="my-12 px-2 text-start lg:my-20">
          <h1 className="font-wide mb-4 text-3xl lg:text-[40px] font-carbon uppercase">
            Systematisk investering
          </h1>
          <div className="text-xl text-gray-400 tracking-tighter lg:text-2xl font-rubik">
            Investerar hela kapitalet i topp 3 fonder den 25e varje månad,
            baserat på sammansatt momentum.
          </div>
        </div>
      </header>
      <main className="container mx-auto px-4">
        <div className="mb-16 flex gap-8 flex-wrap">
          <div className="w-full lg:max-w-1/3 lg:w-2/5">
            <h2 className="mb-8 text-center ml-4 tracking-[0.02em] uppercase text-gray-100 text-lg font-bold space-x-2 font-carbon">
              Allokering
            </h2>
            <div className="group relative">
              {/* <div className="animate-tilt absolute -inset-0.5 rounded-lg bg-gradient-to-r from-pink-600 to-purple-600 opacity-75 blur transition duration-1000 group-hover:opacity-100 group-hover:duration-200"></div> */}
              <div className="relative divide-y divide-white divide-opacity-5 overflow-hidden rounded-xl bg-black border-zinc-800 border-solid border">
                {decoratedAllocated.length > 0 &&
                  decoratedAllocated.map((f) => (
                    <SelectedFund key={f.fundId} fund={f} />
                  ))}
              </div>
            </div>
          </div>

          <div className="flex grow flex-col min-h-[350px]">
            <h2 className="mb-8 text-center text-gray-100 font-carbon uppercase font-bold text-lg">
              Utveckling
            </h2>
            <div className="flex grow border-zinc-800 border-solid border p-6 rounded-xl">
              <AccountChart />
            </div>
          </div>
        </div>

        <div className="my-8 lg:my-16">
          <AllocateTimer />
        </div>


        <h2 className="mb-8 text-center text-gray-100 font-carbon font-bold uppercase text-lg">
          Aktuell ranking
        </h2>
        <div className="w-full divide-y divide-white divide-opacity-5 overflow-hidden rounded-xl bg-[#0F0F10]">
          {list
            .sort((a, b) => b.score - a.score)
            .map((fund) => (
              <Fund key={fund.name} fund={fund} />
            ))}
        </div>
      </main>
      <footer className="pt-8 text-center text-sm">
        <span className="text-gray-500">Skapad av </span>
        <a href="https://twitter.com/linuws">@linuws</a>
      </footer>
    </div>
  );
}

export default App;
